<template>
    <div class="terms">
        <navbar/>
        <div class="terms__content">
            <div class="terms__content__maintitle">
                <h1> Regulamin sklepu internetowego Kompre</h1>
            </div>
            <div class="terms__content__info">
                <h3>
                    Określający m.in. zasady zawierania umów sprzedaży poprzez Sklep, zawierający najważniejsze informacje o Sprzedawcy, Sklepie oraz o prawach Konsumenta.
                </h3>
            </div>
            <div class="terms__content__info">
                <h3>Postanowienia dotyczące Przedsiębiorcy uprzywilejowanego mają zastosowanie do umów zawartych od dnia 1 stycznia 2021 r.</h3>
            </div>
            <div class="terms__content__info">
                <h3>§ 1 Definicje</h3>
                <span>
                    Dni robocze – dni od poniedziałku do piątku za wyjątkiem dni ustawowo wolnych od pracy.
                </span>
                <span>
                    Konsument – konsument w rozumieniu przepisów Kodeksu cywilnego.
                </span>
                <span>
                    Konto – uregulowana odrębnym regulaminem nieodpłatna funkcja Sklepu (usługa świadczona drogą
elektroniczną), dzięki której Kupujący może założyć w Sklepie swoje indywidualne konto.
                </span>
                <span>
                    Kupujący – każdy podmiot kupujący w Sklepie.
                </span>
                <span>
                    Kupujący uprzywilejowany – Konsument lub Przedsiębiorca uprzywilejowany.
                </span>
                <span>
                    Przedsiębiorca uprzywilejowany – osoba fizyczna zawierająca ze Sprzedawcą umowę bezpośrednio
związaną z jej działalnością gospodarczą, ale nieposiadającą dla niej charakteru zawodowego
(definicja obowiązuje dla umów zawartych od dnia 1 stycznia 2021 r.).
                </span>
                                <span>
                    Regulamin – niniejszy regulamin.
                </span>
                                <span>
                    Sklep – sklep internetowy KOMPRE prowadzony przez Sprzedawcę pod adresem
https://www.kompre.pl.
                </span>
                                <span>
Sprzedawca – KOMPRE Sp. z o.o. z siedzibą pod adresem ul. Wesoła 37, 63-500 Ostrzeszów, wpisana
do Krajowego Rejestru Sądowego - rejestru przedsiębiorców przez SĄD REJONOWY POZNAŃ - NOWE
MIASTO I WILDA W POZNANIU, IX WYDZIAŁ GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO, pod
nr KRS 0000636486, NIP 5140342453, nr REGON 365361778.
                </span>


                
            </div>
            <div class="terms__content__info">
                <h3>
                    § 2 Kontakt ze sprzedawcą
                </h3>
                <span>
                    1. Adres pocztowy: ul. Wesoła 37, 63-500 Rogaszyce
                </span>
                <span>
                    2. Adresy e-mail: Dział sprzedaży: sklep@kompre.pl, Dział techniczny i reklamacje: serwis@kompre.pl 
                </span>
                <span>
                    3. Telefon: Dział sprzedaży: 510 188 531, Dział techniczny i reklamacje: 661 801 461
                </span>
            </div>

            <div class="terms__content__info">
                <h3>
                    § 3 Wymogi Techniczne
                </h3>
                <span>
                    1. Dla prawidłowego funkcjonowania Sklepu potrzebne jest: urządzenie z dostępem do Internetu, przeglądarka internetowa obsługująca JavaScript oraz pliki cookies.
                </span>
                <span>
                    2. Dla złożenia zamówienia w Sklepie, poza wymogami określonymi w ust. 1, niezbędne jest
aktywne konto e-mail.
                </span>
            </div>

                        <div class="terms__content__info">
                <h3>
                    § 4 Zakupy w sklepie
                </h3>
                <span>
                    1. Ceny towarów widoczne w Sklepie są całkowitymi cenami za towar.
                </span>
                <span>
                    2. Sprzedawca zwraca uwagę, że na całkowitą cenę zamówienia składają się wskazane w
                    Sklepie: cena za towar oraz, jeśli w danym przypadku ma to zastosowanie, koszty dostawy
                    towaru.
                </span>
                <span>
                    3. Wybrany do kupienia towar należy dodać do koszyka w Sklepie.
                </span>
                                <span>
                    4. Następnie Kupujący wybiera z dostępnych w Sklepie: sposób dostawy towaru oraz metodę
płatności za zamówienie, a także podaje dane niezbędne do zrealizowania złożonego
zamówienia.
                </span>
                                <span>
                    5. Zamówienie zostaje złożone w momencie potwierdzenia jego treści i zaakceptowania
Regulaminu przez Kupującego.
                </span>
                                <span>
                    6. Złożenie zamówienia jest tożsame z zawarciem umowy sprzedaży pomiędzy Kupującym a
Sprzedawcą..
                </span>
                <span>
                    7. Sprzedawca przekaże Kupującemu uprzywilejowanemu potwierdzenie zawarcia umowy
sprzedaży na trwałym nośniku najpóźniej w momencie dostarczenia towaru.
                </span>
                <span>
                    8. Kupujący może zarejestrować się w Sklepie, tj. założyć w nim Konto, lub dokonywać zakupów
bez rejestracji poprzez podawanie swoich danych przy każdym ewentualnym zamówieniu.
                </span>
            </div>

            <div class="terms__content__info">
                <h3>
                    § 5 Płatności
                </h3>
                <span>
                    1. Każde zamówienie złożone w Sklepie internetowym zostaje potwierdzone fiskalnym
dokumentem sprzedaży - fakturą 23% VAT.
                </span>
                <span>
                    2. Na fakturze obok nazwy produktu, znajduje się również informacja o licencji, jeśli sprzęt
takową posiada oraz kwota za wysyłkę.
                </span>
                <span>
                    3. Na fakturze zostaje wyszczególniony sposób płatności: przelew, payu, pobranie, gotówka.
                </span>
                                <span>
                    4. Sprzedawca nie ponosi odpowiedzialności za błędne dane Kupującego wskazane przez niego,
jako właściwe do wystawienia faktury VAT.
                </span>
                                <span>
                    5. Za złożone zamówienie można zapłacić, w zależności od wyboru Kupującego:
                </span>
                                <span>
                    a) zwykłym przelewem na rachunek bankowy Sprzedawcy: 89 1140 1993 0000 3476
1600 1001 mBank - przed dostarczeniem towaru lub po dostarczeniu towaru w
terminie uwzględnionym na fakturze z odroczonym terminem płatności (wymaga to
podpisania umowy regulującej warunki takiej płatności przez Kupującego i
Sprzedającego)
                </span>
                                <span>
                    b) za pomocą karty płatniczej
                </span>
                                                <span>
                    c) za pośrednictwem platformy płatniczej
                </span>
                                                <span>
                    d) za pobraniem, tj. gotówką w momencie dostarczenia towaru do Kupującego (do
kwoty 5 tys. zł)
                </span>
                                                <span>
                    e) kartą lub gotówką w momencie odbioru osobistego towaru.
                </span>
                                                                <span>
                    6. W przypadku wybrania przez Kupującego płatności z góry, za zamówienie należy zapłacić w
terminie 7 Dni kalendarzowych od złożenia zamówienia.
                </span>
                                                                                <span>
7. Sprzedawca informuje, że w przypadku niektórych metod płatności, ze względu na ich
specyfikę, opłacenie zamówienia tą metodą jest możliwe wyłącznie bezpośrednio po złożeniu
zamówienia.
                </span>
                                                                                <span>
8. Kupujący dokonując zakupów w Sklepie akceptuje stosowanie faktur elektronicznych przez
Sprzedawcę.
                </span>
            </div>

                        <div class="terms__content__info">
                <h3>
                    § 6 Realizacja zamówień
                </h3>
                <span>
                    1. Sprzedawca jest obowiązany do dostarczenia towaru bez wad - chyba że Sprzedawca
poinformował Kupującego o wadach towaru przed zawarciem umowy sprzedaży.
                </span>
                <span>
                    2. Termin realizacji zamówienia wskazany jest w Sklepie.
                </span>
                <span>
                    3. W przypadku, gdy Kupujący wybrał płatność z góry za zamówienie, Sprzedawca przystąpi do
realizacji zamówienia po jego opłaceniu.
                </span>
                                <span>
4. W sytuacji, gdy w ramach jednego zamówienia Kupujący zakupił towary o różnym terminie
realizacji, zamówienie zostanie zrealizowane w terminie właściwym dla towaru o najdłuższym
terminie.
                </span>
                                <span>
                    5. Wszystkie uwagi Kupującego dotyczące zamówienia powinny znaleźć się w stosownej
rubryce.
                </span>
                                <span>
                    6. Towar dostarczany jest wyłącznie na terytorium Rzeczypospolitej Polskiej.
                </span>
                                <span>
                    7. Towary zakupione w Sklepie dostarczane są w zależności od tego jaką metodę dostawy
wybrał Kupujący:
                </span>
                                <span>
                    a) Za pośrednictwem firmy kurierskiej
                </span>
                                                <span>
                    b) Do paczkomatów InPost
                </span>
                                                <span>
                    c) Drogą elektroniczną – w przypadku treści cyfrowych
                </span>
                                                                <span>
                    8. Kupujący może odebrać towar osobiście w siedzibie firmy lub punktach odbioru w godzinach
otwarcia.
                </span>
                                                                                <span>
9. W przypadku wybrania odbioru osobistego przez Kupującego, towar będzie gotowy do
odbioru we wskazanym terminie realizacji zamówienia, a w przypadku gdy Sprzedawca
wskazał termin wysłania towaru – w tym terminie.
                </span>
                                                                                <span>
10. W okresie zwiększonej sprzedaży lub zakupu hurtowego czas na zrealizowanie zamówienia
może się wydłużyć.
                </span>
                                                                                                <span>
11. W przypadku zamówień powyżej 5 sztuk sprzętu lub jeśli ilość zamówionego towaru
przekracza 4 paczki, warunki dostawy i opłata transportowa ustalana jest indywidualnie.
                </span>
                                                                                                <span>
12. Kupujący zobowiązany jest do sprawdzenia stanu paczki oraz jej zawartości bezpośrednio
przy kurierze.
                </span>
                                                                                                <span>
13. Z chwilą wydania towaru Kupującemu, przechodzi na niego prawo własności towaru,
uprawnienia i wszelkie ryzyka związane z posiadaniem i użytkowaniem towaru, a w
szczególności ryzyko utraty lub uszkodzenia.
                </span>
                                                                                                                <span>
14. W przypadku stwierdzenia jakichkolwiek uszkodzeń paczki lub sprzętu mogących mieć
związek z dostawą, wymaga się spisania protokołu szkody z kurierem oraz zgłoszenie tego
faktu w ciągu 24 godzin od daty otrzymania przesyłki pod adresem: serwis@kompre.pl.
Zaleca się, by Kupujący sporządził i dostarczył także dokumentację fotograficzną uszkodzeń.
                </span>
                                                                                                                <span>
15. Reklamacje paczek nie będą uwzględniane bez protokołu szkody lub błędnie wypełnionego
protokołu szkody spisanego przez kuriera.
                </span>
                                                                                                                <span>
16. Pakowanie paczek oraz przekazywanie ich kurierowi odbywa się w siedzibie Sprzedającego
pod obecnością kamer.
                </span>
                                                                                                                                <span>
17. Kupujący nie może obciążyć Sprzedającego winą za uszkodzenie paczki, jeśli nie otworzył
paczki przy kurierze, jeśli pokwitował odbiór paczki w stanie nienaruszonym, a w razie
uszkodzeń nie dopilnował spisania protokołu szkody w obecności kuriera.
                </span>
            </div>

                        <div class="terms__content__info">
                <h3>
                    § 7 Prawo odstąpienia od umowy
                </h3>
                <span>
1. Kupujący uprzywilejowany ma prawo odstąpić od umowy zawartej ze Sprzedawcą za
pośrednictwem Sklepu, z zastrzeżeniem § 8 Regulaminu, w terminie 14 dni bez podania
jakiejkolwiek przyczyny. W tym celu Kupujący powinien wypełnić oświadczenie o rezygnacji z
zakupu, które wypełnia na stronie: serwis.kompre.pl Wypełniony i podpisany formularz
należy wydrukować i dołączyć wraz ze zwracanym sprzętem i kompletem dokumentów.
                </span>
                                <span>
                                    2. Przedmiot można dostarczyć osobiście do siedziby firmy lub przesłać bezpiecznie dowolnym
środkiem transportu.
                    
                </span>
                                <span>
                                    3. W przypadku odstąpienia od umowy sprzedaży towaru zakupionego w zestawie z innym
produktem zwrotowi podlega cały zestaw.
                    
                </span>
                                <span>
                                    4. Termin do odstąpienia od umowy wygasa po upływie 14 dni od dnia:
                </span>
                                <span>
                                    a)
                                    w którym Kupujący uprzywilejowany wszedł w posiadanie towaru lub w którym
osoba trzecia inna niż przewoźnik i wskazana przez Kupującego uprzywilejowanego
weszła w posiadanie tego towaru;
                    
                </span>
                                <span>
                                    b) w którym Kupujący uprzywilejowany wszedł w posiadanie ostatniej z rzeczy lub w
którym osoba trzecia, inna niż przewoźnik i wskazana przez Kupującego
uprzywilejowanego, weszła w posiadanie ostatniej z rzeczy w przypadku umowy
zobowiązującej do przeniesienia własności wielu rzeczy, które dostarczane są
osobno;
                    
                </span>
                                <span>
                                    c) zawarcia umowy - w przypadku umowy o dostarczenie treści cyfrowych.
                    
                </span>

                                                <span>
                                    5. Aby Kupujący uprzywilejowany mógł skorzystać z prawa odstąpienia od umowy musi
poinformować Sprzedawcę, korzystając z danych podanych w § 2 Regulaminu, o swojej
decyzji o odstąpieniu od umowy w drodze jednoznacznego oświadczenia (na przykład pismo
wysłane pocztą lub informacja przekazana pocztą elektroniczną).
                    
                </span>
                <span>
                    6. Aby zachować termin do odstąpienia od umowy wystarczy, że Kupujący uprzywilejowany
wyśle informację dotyczącą wykonania przysługującego mu prawa odstąpienia od umowy
przed upływem terminu do odstąpienia od umowy.
                </span>
                <span>
                    7. Kupujący ponosi koszt odesłania Produktu do Sprzedającego. Jeżeli Kupujący wybrał sposób
dostarczenia rzeczy inny niż najtańszy zwykły sposób dostarczenia oferowany przez
przedsiębiorcę, przedsiębiorca nie jest zobowiązany do zwrotu konsumentowi poniesionych
przez niego dodatkowych kosztów.
                </span>
                <span>
                    8. W przypadku, kiedy dokumentem sprzedaży jest faktura, należy ją zachować - do dokumentu
                    zostanie wystawiona korekta, na podstawie której Sprzedający dokona zwrotu należności.
                </span>

            </div>

                        <div class="terms__content__info">
                <h3>
                    Skutki odstąpienia od umowy
                </h3>
                <span>
                    9. W przypadku odstąpienia od zawartej umowy Sprzedawca zwraca Kupującemu
uprzywilejowanemu wszystkie otrzymane od niego płatności, w tym koszty dostarczenia
towaru (z wyjątkiem dodatkowych kosztów wynikających z wybranego przez Kupującego
uprzywilejowanego sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia oferowany przez Sprzedawcę), niezwłocznie, a w każdym przypadku nie później niż 14 dni od
dnia, w którym Sprzedawca został poinformowany o decyzji Kupującego uprzywilejowanego
o wykonaniu prawa odstąpienia od umowy.
                </span>
                <span>
                    10. Zwrotu płatności Sprzedawca dokona przy użyciu takich samych sposobów płatności, jakie
zostały przez Kupującego uprzywilejowanego użyte w pierwotnej transakcji, chyba że
Kupujący uprzywilejowany zgodzi się na inne rozwiązanie, w każdym przypadku Kupujący
uprzywilejowany nie poniesie żadnych opłat w związku z tym zwrotem.
                </span>
                <span>
                    11. Sprzedawca może wstrzymać się ze zwrotem płatności do czasu otrzymania towaru.
                </span>
                <span>
12. Sprzedawca prosi o zwracanie towaru na adres: ul. Wesoła 37, 63-500 Rogaszyce
niezwłocznie, a w każdym razie nie później niż 14 dni od dnia, w którym Kupujący
uprzywilejowany poinformował Sprzedawcę o odstąpieniu od umowy sprzedaży. Termin jest
zachowany, jeżeli Kupujący uprzywilejowany odeśle towar przed upływem terminu 14 dni.
                </span>
                <span>
                    13. Kupujący uprzywilejowany ponosi bezpośrednie koszty zwrotu towaru.
                </span>
                <span>
                    14. Kupujący uprzywilejowany odpowiada tylko za zmniejszenie wartości towaru wynikające z
korzystania z niego w sposób inny niż było to konieczne do stwierdzenia charakteru, cech i
funkcjonowania towaru.
                </span>
                <span>
                    15. Jeśli towar ze względu na swój charakter nie może zostać odesłany w zwykłym trybie,
Kupujący uprzywilejowany również będzie musiał ponieść bezpośrednie koszty zwrotu
towarów.
                </span>
                <span>
                    16. W przypadku wystąpienia konieczności zwrotu środków za transakcję dokonaną przez
Kupującego uprzywilejowanego kartą płatniczą, Sprzedawca dokona zwrotu na rachunek
bankowy przypisany do tej karty płatniczej.
                </span>
                <span>
                    17. Kupujący ponosi odpowiedzialność za zmniejszenie wartości rzeczy będącej wynikiem
korzystania z niej w sposób wykraczający poza konieczny dla stwierdzenia charakteru, cech i
funkcjonowania rzeczy. Jeśli Kupujący uszkodzi Przedmiot lub w jakikolwiek sposób narazi
Sprzedającego na dodatkowe koszty (jeśli np. zwracany przedmiot będzie wymagał
ponownego zainstalowania systemu operacyjnego), wówczas kwota zwrotu może zostać
pomniejszona.
                </span>

            </div>
            <div class="terms__content__info">
                <h3>§ 8 Wyjątki od prawa odstąpienia umowy</h3>
                <span>
1. Prawo odstąpienia od umowy zawartej na odległość, o którym mowa w § 7 Regulaminu, nie
przysługuje w odniesieniu do umowy:
                </span>
                <span>
   a) w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana
według specyfikacji Kupującego uprzywilejowanego lub służąca zaspokojeniu jego
zindywidualizowanych potrzeb;                 
                </span>
                <span>
                  b) w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym
opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na

ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte
po dostarczeniu;  
                </span>
                <span>
                    c) w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na
swój charakter, zostają nierozłącznie połączone z innymi rzeczami;
                </span>
                <span>
                    d) w której przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo
programy komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli
opakowanie zostało otwarte po dostarczeniu.
                </span>
                <span>
                    e) o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli
spełnianie świadczenia rozpoczęło się za wyraźną zgodą Kupującego
uprzywilejowanego przed upływem terminu do odstąpienia od umowy i po
poinformowaniu go przez Sprzedawcę o utracie prawa odstąpienia od umowy.
                </span>
                <span>
                    f) Jeśli Kupujący otrzymał towar zapakowany w oryginalne zapieczętowane opakowanie
i zostanie ono naruszone lub towar zostanie pozbawiony oryginalnych etykiet, lub
będzie posiadać ślady użytkowania, Sprzedawca będzie zobowiązany do zmniejszenia
wartości zwrotu.
                </span>
            </div>

                        <div class="terms__content__info">
                <h3>
                    § 9 Reklamacje
                </h3>
                <span>
                    1. Sprzedawca udziela Kupującemu gwarancji na działanie sprzętu zgodne z informacją
zamieszczoną w opisie przedmiotu. Okres gwarancji także został tam określony.
                </span>
                <span>
                    2. W przypadku wystąpienia wady towaru Konsument ma możliwość reklamowania wadliwego
towaru na podstawie uregulowanej w Kodeksie cywilnym rękojmi lub gwarancji, o ile
gwarancja została udzielona.
                </span>
                <span>
                    3. Sprzedawca prosi o składanie reklamacji na adres pocztowy lub elektroniczny wskazany w § 2
Regulaminu.
                </span>
                                <span>
4. Jeśli okaże się, że dla rozpatrzenia reklamacji konieczne jest dostarczenie wadliwego towaru
do Sprzedawcy, Konsument jest zobowiązany do dostarczenia tego towaru na adres ul.
Wesoła 37, 63-500 Rogaszyce, z dopiskiem „REKLAMACJA”
                </span>
                <span>
5. Sposoby dostarczenie przedmiotu na serwis:
                </span>
                <span>
                    a) Sprzedający zamawia kuriera na wskazany przez Kupującego adres (Kupujący jest
zobowiązany do wydrukowania listu przewozowego i naklejenia go do bezpiecznie
zapakowanej przez siebie paczki).
                </span>
                <span>
                    b) Kupujący może dostarczyć towar do serwisu osobiście lub dowolną firmą kurierską,
jednak kwota za przesyłkę nie powinna przekroczyć najtańszej opcji z cennika
dostawy Sprzedawcy.
                </span>
                <span>
                    6. W przypadku uzasadnionej reklamacji Kupujący nie ponosi kosztów związanych z
dostarczeniem przedmiotu na serwis. Tylko w przypadku niedostarczenia paczki kurierowi,
który zjawił się u Kupującego w ustalonym przez niego terminie, na Kupującego spada

obowiązek na własny koszt dostarczyć reklamowany towar do Sprzedającego. Wysyłka
naprawionego sprzętu do Kupującego, również odbywa się na koszt Sprzedającego.
                </span>
                <span>
                    7. Jeśli reklamowany przedmiot to urządzenie, typu laptop, komputer, monitor, projektor,
prosimy o wysyłkę także okablowania (zasilacze i przewody).
                </span>
                <span>
                    8. Rozpatrzenie reklamacji przez Sprzedawcę nastąpi w terminie do 14 dni.
                </span>
                <span>
                    9. Każdy sprzęt posiada plomby, które uniemożliwiają Kupującemu zmianę lub montowanie
własnych podzespołów. Zerwanie plomby wiąże się jednoznacznie z utratą gwarancji. W
przypadku zamówienia komputera stacjonarnego plomby zabezpieczające znajdują się na
poszczególnych podzespołach, a nie na zewnętrznej obudowie. W przypadku zakupu laptopa
plomby znajdują się na obudowie. W szczególnych przypadkach Sprzedający może
zastosować inny rodzaj zabezpieczania laptopa przed ingerencją Kupującego lub inaczej
umiejscowić plomby, ale wówczas Kupujący zobowiązany jest do wcześniejszego kontaktu ze
Sprzedającym.
                </span>
                <span>
                    10. Nowe baterie podlegają 6 miesięcznej gwarancji.
                </span>
                <span>
                    11. Gwarancji nie podlegają akumulatory/baterie, których czas w opisie przedmiotu wynosił
poniżej 30 minut, ~30 minut lub posiadały adnotację „do regeneracji”, „rozważ wymianę”.
Akumulatory/baterie używane, których czas pracy wynosi ponad 30 minut, podlega zawsze
30 dniowej gwarancji.
                </span>
                <span>
                    12. Nowe dyski podlegają 24 miesięcznej gwarancji.
                </span>
                <span>
                    13. Gwarancji nie podlega ekran, na którym wystąpiło do 3 „bad pikseli”.
                </span>
                <span>
                    14. Gwarant nie odpowiada za szkody i straty będące następstwem stwierdzenia wadliwości
sprzętu, takich jak np. utrata danych oraz nie bierze odpowiedzialności za niekompatybilność
oprogramowania oraz innych podzespołów ze sprzętem klienta.
                </span>
                <span>
                    15. Gwarancja nie obejmuje usterek, których przyczyną jest niewłaściwa praca oprogramowania.
                </span>
                <span>
                    16. Gwarancja nie obejmuje czynności, które należą do normalnej obsługi eksploatacyjnej, jak
np. zainstalowanie oprogramowania, konfiguracja, konserwacja, czyszczenie. Kupujący
zobowiązany jest do wykonania tych czynności we własnym zakresie.
                </span>
                <span>
                    17. Gwarancją nie są objęte uszkodzenia mechaniczne i wywołane nimi wady, uszkodzenia i
wady spowodowane niewłaściwym lub niezgodnym z instrukcją użytkownika
przechowywania, konserwacji, instalacji, podłączenia sprzętu do wadliwie działającej
instalacji elektrycznej, dokonywanych przez użytkownikach samowolnych napraw i zmian.
                </span>
                <span>
                    18. W przypadku sprzedaży towaru w obrocie między przedsiębiorcami strony wyłączają
odpowiedzialność sprzedającego z tytułu rękojmi za wady fizyczne i prawne rzeczy, w
szczególności ustalają, iż nie ponosi on żadnej odpowiedzialności także za wady ukryte
przedmiotu sprzedaży. Czas rękojmi przy sprzedaży osobie fizycznej to 12 miesięcy.
                </span>
                <span>
                    19. Gwarancja zostaje przedłużona o czas przebywania towaru w serwisie.
                </span>
                <span>
                    20. Jeżeli usterka jest bardzo poważna i Sprzedający nie ma możliwości jej usunięcia lub wymiany
podzespołów wówczas Kupujący otrzymuje zwrot środków na konto.
                </span>
                <span>
                    21. Nabywca traci gwarancję na skutek:
                </span>
                <span>
                    a) Upływu terminu gwarancji.
                </span>
                <span>
                    b) Zerwania plomb gwarancyjnych umieszczonych na sprzęcie przez Gwaranta.
                </span>
                                <span>
                    c) Niewłaściwego użytkowania urządzenia lub niewłaściwego przechowywania.
                </span>
                                <span>
                                    d) Uszkodzeń mechanicznych urządzenia.
                </span>
                                <span>
                                    e) Utraty karty gwarancyjnej.
                </span>
                                <span>
                                    f) Napraw dokonywanych we własnym zakresie przez Nabywcę.
                </span>
                                <span>
                                    g) Demontażu podzespołów, otwierania obudowy.
                </span>
                <span>
                    h) Stwierdzenia przez serwis śladów zalania sprzętu.
                </span>
            </div>

                        <div class="terms__content__info">
                <h3>
                    Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń.
                </h3>
                <span>
W przypadku, gdy postępowanie reklamacyjne nie przyniesie oczekiwanego przez
Konsumenta rezultatu, Konsument może skorzystać m.in. z:
                </span>
                                <span>
                    a) mediacji prowadzonej przez właściwy terenowo Wojewódzki Inspektorat Inspekcji
Handlowej, do którego należy się zwrócić z wnioskiem o mediację. Wykaz
Inspektoratów znajduje się tutaj:
https://www.uokik.gov.pl/wazne_adresy.php#faq595;
                </span>
                                <span>
                                    b) pomocy właściwego terenowo stałego polubownego sądu konsumenckiego
działającego przy Wojewódzkim Inspektoracie Inspekcji Handlowej, do którego
należy złożyć wniosek o rozpatrzenie sprawy przed sądem polubownym. Wykaz
sądów dostępny jest pod adresem:
https://www.uokik.gov.pl/wazne_adresy.php#faq596;
                    
                </span>
                                <span>
                    c) bezpłatnej pomocy miejskiego lub powiatowego rzecznika Konsumentów;
                </span>
                                <span>
                    d) internetowej platformy ODR dostępnej pod adresem:
https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks.
                </span>
            </div>


            <div class="terms__content__info">
                <h3>
                    § 10 Dane osobowe
                </h3>
                <span>
                    1. Administratorem danych osobowych przekazanych przez Kupującego podczas korzystania ze
Sklepu jest Sprzedawca. Szczegółowe informacje dotyczące przetwarzania danych osobowych
przez Sprzedawcę – w tym o pozostałych celach oraz podstawach przetwarzania danych, a
także o odbiorcach danych – znajdują się w dostępnej w Sklepie Polityce prywatności – ze
względu na zasadę przejrzystości, zawartą w ogólnym rozporządzeniu Parlamentu
Europejskiego i Rady (UE) o ochronie danych – „RODO”.
                </span>

                <span>
                    2. Celem przetwarzania danych Kupującego przez Sprzedawcę, podanych przez Kupującego w
związku z zakupami w Sklepie, jest realizacja zamówień. Podstawą przetwarzania danych
osobowych w tym przypadku jest:
                </span>
                <span>
                    a) umowa lub działania podejmowane na żądanie Kupującego, zmierzające do jej
zawarcia (art. 6 ust. 1 lit. b RODO),
                </span>
                <span>
                    b) ciążący na Sprzedawcy obowiązek prawny związany z rachunkowością (art. 6 ust. 1
lit. c) oraz
                </span>
                <span>
                   c)  prawnie uzasadniony interes Sprzedawcy, polegający na przetwarzaniu danych w
celu ustalenia, dochodzenia lub obrony ewentualnych roszczeń (art. 6 ust. 1 lit. f
RODO).
                </span>
                <span>
                    3. Podanie danych przez Kupującego jest dobrowolne, ale jednocześnie konieczne do zawarcia
umowy. Niepodanie danych uniemożliwi zawarcie umowy w Sklepie.
                </span>
                <span>
                    4. Dane Kupującego podane w związku z zakupami w Sklepie będą przetwarzane do momentu,
w którym:
                </span>
                <span>
                    a) przestanie obowiązywać umowa zawarta między Kupującym a Sprzedawcą;
                </span>
                <span>
                    b) na Sprzedawcy przestanie ciążyć obowiązek prawny, zobowiązujący go do
przetwarzania danych Kupującego;
                </span>
                <span>
                    c) ustanie możliwość dochodzenia roszczeń przez Kupującego lub Sprzedawcę,
związanych z umową zawartą przez Sklep;
                </span>
                <span>
                    d) zostanie przyjęty sprzeciw Kupującego wobec przetwarzania jego danych osobowych
– w przypadku gdy podstawą przetwarzania danych był uzasadniony interes
Sprzedawcy – w zależności od tego, co ma zastosowanie w danym przypadku i co nastąpi najpóźniej.
                </span>
                <span>
                    5. Kupującemu przysługuje prawo żądania:
                </span>
                <span> 
                    a) dostępu do swoich danych osobowych,
                </span>
                <span> b) ich sprostowania</span>
                <span> c) usunięcia</span>
                <span> d) ograniczenia przetwarzania</span>
                <span> e) przeniesienia danych do innego administratora
a także prawo: wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania danych z
przyczyn związanych ze szczególną sytuacją Kupującego – wobec przetwarzania
dotyczących go danych osobowych, opartego na art. 6 ust. 1 lit. f RODO (tj. na
prawnie uzasadnionych interesach realizowanych przez administratora).</span>
                <span>
                    6. W celu realizacji swoich praw, Kupujący powinien skontaktować się ze Sprzedawcą przy
wykorzystaniu danych z § 2 Regulaminu.
                </span>
                <span>
                    7. W przypadku gdy Kupujący uzna, że jego dane są przetwarzane niezgodnie z prawem,
Kupujący może złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych.
                </span>
            </div>

                        <div class="terms__content__info">
                <h3>
                     § 11 Zastrzeżenia
                </h3>
                <span>
                    1. Zakazane jest dostarczanie przez Kupującego treści o charakterze bezprawnym.
                </span>
                <span>
                    2. Każdorazowo składane w Sklepie zamówienie stanowi odrębną umowę sprzedaży i wymaga
osobnej akceptacji Regulaminu. Umowa zawierana jest na czas i w celu realizacji zamówienia.
                </span>
                <span>
                    3. Umowy zawierane na podstawie Regulaminu zawierane są w języku polskim.
                </span>
                <span>
                    4. W przypadku ewentualnego sporu z Kupującym niebędącym Kupującym uprzywilejowanym,
sądem właściwym będzie sąd właściwy dla siedziby Sprzedawcy.
                </span>
                <span>
                    5. Postanowienia dotyczące towarów i umowy sprzedaży stosuje się odpowiednio do treści
cyfrowych i umowy o dostarczanie treści cyfrowych, o ile Regulamin nie określa tych kwestii
odrębnie.
                </span>
                <span>
                    6. Wszelka odpowiedzialność Sprzedawcy w stosunku do Kupującego niebędącego Kupującym
uprzywilejowanym, w granicach prawem dopuszczonych, jest wyłączona.
                </span>
                <span>
                    7. Odpowiedzialność z tytułu rękojmi względem Przedsiębiorcy uprzywilejowanego jest
wyłączona.
                </span>
            </div>

            <div class="terms__content__maintitle">
                <h1> Regulamin konta</h1>
            </div>

                        <div class="terms__content__info">
                <h3>
                    Określający m.in. zasady zawierania umów sprzedaży poprzez Sklep, zawierający najważniejsze informacje o Sprzedawcy, Sklepie oraz o prawach Konsumenta.
                </h3>
            </div>

                        <div class="terms__content__info">
                <h3>§ 1 Definicje</h3>
                <span>
                    Konsument – konsument w rozumieniu przepisów Kodeksu cywilnego.
                </span>
                <span>
                    Konto – uregulowana odrębnym regulaminem nieodpłatna funkcja Sklepu (usługa świadczona drogą
elektroniczną), dzięki której Kupujący może założyć w Sklepie swoje indywidualne konto.
                </span>
                <span>
                    Kupujący – każdy podmiot kupujący w Sklepie.
                </span>
                <span>
                    Kupujący uprzywilejowany – Konsument lub Przedsiębiorca uprzywilejowany.
                </span>
                <span>
                    Przedsiębiorca uprzywilejowany – osoba fizyczna zawierająca ze Sprzedawcą umowę bezpośrednio
związaną z jej działalnością gospodarczą, ale nieposiadającą dla niej charakteru zawodowego
(definicja obowiązuje dla umów zawartych od dnia 1 stycznia 2021 r.).
                </span>
                                <span>
                    Regulamin – niniejszy regulamin.
                </span>
                                <span>
                    Sklep – sklep internetowy KOMPRE prowadzony przez Sprzedawcę pod adresem
https://www.kompre.pl.
                </span>
                                <span>
Sprzedawca – KOMPRE Sp. z o.o. z siedzibą pod adresem ul. Wesoła 37, 63-500 Ostrzeszów, wpisana
do Krajowego Rejestru Sądowego - rejestru przedsiębiorców przez SĄD REJONOWY POZNAŃ - NOWE
MIASTO I WILDA W POZNANIU, IX WYDZIAŁ GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO, pod
nr KRS 0000636486, NIP 5140342453, nr REGON 365361778.
                </span>


                
            </div>

            <div class="terms__content__info">
                <h3>
                    § 2 Kontakt ze sprzedawcą
                </h3>
                <span>
                    1. Adres pocztowy: ul. Wesoła 37, 63-500 Rogaszyce
                </span>
                <span>
                    2. Adresy e-mail: sklep@kompre.pl
                </span>
                <span>
                    3. Telefon: 510188531
                </span>
            </div>

                        <div class="terms__content__info">
                <h3>
                    § 3 Wymogi techniczne
                </h3>
                <span>
                    1. Dla prawidłowego funkcjonowania i założenia Konta potrzebne jest: aktywne konto e-mail, urządzenie z dostępem do Internetu, przeglądarka internetowa obsługująca JavaScript i pliki cookies
                </span>
            </div>

            <div class="terms__content__info">
                <h3>
                    § 4 Konto
                </h3>
                <span>
                    1. Założenie Konta jest całkowicie dobrowolne i zależne od woli Kupującego.
                </span>
                                <span>
                    2. Konto daje Kupującemu dodatkowe możliwości, takie jak: przeglądanie historii
zamówień złożonych przez Kupującego w Sklepie, sprawdzenie statusu zamówienia
czy samodzielna edycja danych Kupującego.
                </span>
                                <span>
                    3. W celu założenia Konta należy wypełnić stosowny formularz w Sklepie.
                </span>
                                <span>
                    4. W momencie założenia Konta zawierana jest na czas nieokreślony pomiędzy
Kupującym a Sprzedawcą umowa w zakresie prowadzenia Konta na zasadach
wskazanych w Regulaminie.
                </span>
                                <span>
                    5. Kupujący może bez ponoszenia jakichkolwiek kosztów w każdym czasie zrezygnować
z Konta.
                </span>
                <span>
                    6. W celu dokonania rezygnacji z Konta należy wysłać swoją rezygnację do Sprzedawcy
na adres e-mail: sklep@kompre.pl, czego skutkiem będzie niezwłoczne usunięcie
Konta oraz rozwiązanie umowy w zakresie prowadzenia Konta.
                </span>
            </div>

                        <div class="terms__content__info">
                <h3>
                    § 5 Reklamcje dot. konta
                </h3>
                <span>
                    1. Reklamacje dotyczące funkcjonowania Konta należy kierować na adres e-mail
sklep@kompre.pl.
                </span>
                                <span>
                    2. Rozpatrzenie reklamacji przez Sprzedawcę nastąpi w terminie do 14 dni.
                </span>
            </div>


                        <div class="terms__content__info">
                <h3>
                    Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń.
                </h3>
                <span>
W przypadku, gdy postępowanie reklamacyjne nie przyniesie oczekiwanego przez
Konsumenta rezultatu, Konsument może skorzystać m.in. z:
                </span>
                                <span>
                    a) mediacji prowadzonej przez właściwy terenowo Wojewódzki Inspektorat Inspekcji
Handlowej, do którego należy się zwrócić z wnioskiem o mediację. Wykaz
Inspektoratów znajduje się tutaj:
https://www.uokik.gov.pl/wazne_adresy.php#faq595;
                </span>
                                <span>
                                    b) pomocy właściwego terenowo stałego polubownego sądu konsumenckiego
działającego przy Wojewódzkim Inspektoracie Inspekcji Handlowej, do którego
należy złożyć wniosek o rozpatrzenie sprawy przed sądem polubownym. Wykaz
sądów dostępny jest pod adresem:
https://www.uokik.gov.pl/wazne_adresy.php#faq596;
                    
                </span>
                                <span>
                    c) bezpłatnej pomocy miejskiego lub powiatowego rzecznika Konsumentów;
                </span>
                                <span>
                    d) internetowej platformy ODR dostępnej pod adresem:
https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks.
                </span>
            </div>

                        <div class="terms__content__info">
                <h3>
                    § 6 Dane osobowe
                </h3>
                <span>
                    1. Administratorem danych osobowych przekazanych przez Kupującego podczas korzystania ze
Sklepu jest Sprzedawca. Szczegółowe informacje dotyczące przetwarzania danych osobowych
przez Sprzedawcę – w tym o pozostałych celach oraz podstawach przetwarzania danych, a
także o odbiorcach danych – znajdują się w dostępnej w Sklepie Polityce prywatności – ze
względu na zasadę przejrzystości, zawartą w ogólnym rozporządzeniu Parlamentu
Europejskiego i Rady (UE) o ochronie danych – „RODO”.
                </span>

                <span>
                    2. Celem przetwarzania danych Kupującego przez Sprzedawcę, podanych przez Kupującego w
związku z zakupami w Sklepie, jest realizacja zamówień. Podstawą przetwarzania danych
osobowych w tym przypadku jest:
                </span>
                <span>
                    a) umowa lub działania podejmowane na żądanie Kupującego, zmierzające do jej
zawarcia (art. 6 ust. 1 lit. b RODO),
                </span>
                <span>
                    b) ciążący na Sprzedawcy obowiązek prawny związany z rachunkowością (art. 6 ust. 1
lit. c) oraz
                </span>
                <span>
                   c)  prawnie uzasadniony interes Sprzedawcy, polegający na przetwarzaniu danych w
celu ustalenia, dochodzenia lub obrony ewentualnych roszczeń (art. 6 ust. 1 lit. f
RODO).
                </span>
                <span>
                    3. Podanie danych przez Kupującego jest dobrowolne, ale jednocześnie konieczne do zawarcia
umowy. Niepodanie danych uniemożliwi zawarcie umowy w Sklepie.
                </span>
                <span>
                    4. Dane Kupującego podane w związku z zakupami w Sklepie będą przetwarzane do momentu,
w którym:
                </span>
                <span>
                    a) przestanie obowiązywać umowa zawarta między Kupującym a Sprzedawcą;
                </span>
                <span>
                    b) na Sprzedawcy przestanie ciążyć obowiązek prawny, zobowiązujący go do
przetwarzania danych Kupującego;
                </span>
                <span>
                    c) ustanie możliwość dochodzenia roszczeń przez Kupującego lub Sprzedawcę,
związanych z umową zawartą przez Sklep;
                </span>
                <span>
                    d) zostanie przyjęty sprzeciw Kupującego wobec przetwarzania jego danych osobowych
– w przypadku gdy podstawą przetwarzania danych był uzasadniony interes
Sprzedawcy – w zależności od tego, co ma zastosowanie w danym przypadku i co nastąpi najpóźniej.
                </span>
                <span>
                    5. Kupującemu przysługuje prawo żądania:
                </span>
                <span> 
                    a) dostępu do swoich danych osobowych,
                </span>
                <span> b) ich sprostowania</span>
                <span> c) usunięcia</span>
                <span> d) ograniczenia przetwarzania</span>
                <span> e) przeniesienia danych do innego administratora
a także prawo: wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania danych z
przyczyn związanych ze szczególną sytuacją Kupującego – wobec przetwarzania
dotyczących go danych osobowych, opartego na art. 6 ust. 1 lit. f RODO (tj. na
prawnie uzasadnionych interesach realizowanych przez administratora).</span>
                <span>
                    6. W celu realizacji swoich praw, Kupujący powinien skontaktować się ze Sprzedawcą przy
wykorzystaniu danych z § 2 Regulaminu.
                </span>
                <span>
                    7. W przypadku gdy Kupujący uzna, że jego dane są przetwarzane niezgodnie z prawem,
Kupujący może złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych.
                </span>
            </div>

                        <div class="terms__content__info">
                <h3>
                    § 7 ZASTRZEŻENIA
                </h3>
                <span>
                    1. Zakazane jest dostarczanie przez Kupującego treści o charakterze bezprawnym.
                </span>
                <span>
                    2. Umowa w zakresie prowadzenia Konta zawierana jest w języku polskim.
                </span>
                <span>
                    3. W przypadku zaistnienia ważnych przyczyn, o których mowa w ust. 4, Sprzedawca
ma prawo do zmiany Regulaminu.
                </span>
                <span>
                    4. Ważnymi przyczynami, o których mowa w ust. 3 są:
                </span>
                <span>
                    a) konieczność dostosowania Sklepu do przepisów prawa mających zastosowanie
do działalności Sklepu
                </span>
                <span>
                    b) poprawa bezpieczeństwa świadczonej usługi
                </span>
                <span>
                    c) zmiana funkcjonalności Konta wymagająca modyfikacji Regulaminu.
                </span>
                <span>
                    5. Kupujący zostanie poinformowany o planowanej zmianie Regulaminu co najmniej na
7 dni przed wprowadzeniem zmiany w życie za pośrednictwem wiadomości e-mail
wysłanej na przypisany do Konta adres.
                </span>
                <span>
                    6. W przypadku gdy Kupujący nie wyrazi akceptacji dla planowanej zmiany, powinien
poinformować o tym Sprzedawcę poprzez wysłanie odpowiedniej wiadomości na
adres e-mail Sprzedawcy sklep@kompre.pl, czego skutkiem będzie rozwiązanie
umowy w zakresie prowadzenia Konta z chwilą wejścia w życie planowanej zmiany
lub wcześniej, jeśli Kupujący zgłosi takie żądanie.
                </span>
                <span>
                    7. W sytuacji gdy Kupujący nie wyrazi sprzeciwu dla planowanej zmiany do chwili
wejścia jej w życie przyjmuje się, że akceptuje ją, co nie stanowi żadnej przeszkody
do rozwiązania umowy w przyszłości.
                </span>
                <span>
                    8. W przypadku ewentualnego sporu z Kupującym niebędącym Kupującym
uprzywilejowanym, sądem właściwym będzie sąd właściwy dla siedziby Sprzedawcy.
                </span>
                
            </div>

                        <div class="terms__content__maintitle">
                <h1> Regulamin newslettera</h1>
            </div>

            <div class="terms__content__info">
                <h3>
                    § 1 Definicje
                </h3>
                <span>
                    Konsument – konsument w rozumieniu przepisów Kodeksu cywilnego.
                </span>
                <span>
Newsletter – nieodpłatnie świadczona drogą elektroniczną usługa, dzięki której Usługobiorca
może otrzymywać od Usługodawcy drogą elektroniczną uprzednio zamówione wiadomości
dotyczące Sklepu, w tym informacje o ofertach, promocjach oraz nowościach w Sklepie.
                </span>
                <span>
                    Przedsiębiorca uprzywilejowany – osoba fizyczna zawierająca ze Sprzedawcą umowę bezpośrednio
związaną z jej działalnością gospodarczą, ale nieposiadającą dla niej charakteru zawodowego
(definicja obowiązuje dla umów zawartych od dnia 1 stycznia 2021 r.).
                </span>
                                <span>
                    Regulamin – niniejszy regulamin.
                </span>
                                <span>
                    Sklep – sklep internetowy KOMPRE prowadzony przez Sprzedawcę pod adresem
https://www.kompre.pl.
                </span>
                                <span>
Usługobiorca - każdy podmiot korzystający z usługi Newsletter.
                </span>
                <span>
                    Usługobiorca uprzywilejowany  – Konsument lub Przedsiębiorca uprzywilejowany.
                </span>
                <span>
                    Usługodawca - KOMPRE Sp. z o.o. z siedzibą pod adresem ul. Wesoła 37, 63-500
Rogaszyce, wpisana do Krajowego Rejestru Sądowego - rejestru przedsiębiorców przez SĄD
REJONOWY POZNAŃ - NOWE MIASTO I WILDA W POZNANIU, IX WYDZIAŁ
GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO, pod nr KRS 0000636486, NIP
5140342453, nr REGON 365361778
                </span>
            </div>

                        <div class="terms__content__info">
                <h3>
                    § 2 Newsletter
                </h3>
                <span>
                    1. Usługobiorca może dobrowolnie skorzystać z usługi Newsletter.
                </span>
                <span>
2. Dla skorzystania z usługi Newsletter niezbędne jest urządzenie z przeglądarką
internetową w najnowszej wersji, obsługującą JavaScript oraz pliki cookies, z
dostępem do sieci Internet oraz aktywne konto e-mail.
                </span>
                <span>
                   3. Wiadomości e-mail wysyłane w ramach tej usługi wysyłane będą na adres e-mail
podany przez Usługobiorcę w momencie zapisywania się do Newslettera.
                </span>
                                <span>
                    4. Usługobiorca, w celu zawarcia umowy i zapisania się do usługi Newsletter, w
pierwszym kroku podaje w przeznaczonym do tego miejscu w Sklepie swój adres e-
mail, na który chce otrzymywać wiadomości przesyłane w ramach Newslettera. W
momencie zapisu na Newsletter zostaje zawarta umowa o świadczenie usługi, a
Usługodawca rozpocznie jej świadczenie na rzecz Usługobiorcy – z zastrzeżeniem ust.
5.
                </span>
                                <span>
                    5. W celu właściwej realizacji usługi Newslettera, Usługobiorca jest obowiązany podać
swój prawidłowy adres e-mail.
                </span>
                                <span>
6. W wiadomościach wysyłanych w ramach Newslettera będzie znajdować się
informacja o możliwości wypisania się z niego, a także link do wypisania się.
                </span>
                <span>
                    7. Usługobiorca może wypisać się z Newslettera, bez podawania przyczyny i ponoszenia
jakichkolwiek kosztów, w każdym momencie, korzystając z opcji, o której mowa w
ust. 6 lub wysyłając wiadomość na adres e-mail Usługodawcy: sklep@kompre.pl.
                </span>
                <span>
                    8. Skorzystanie przez Usługobiorcę z linka do wypisania się z Newslettera lub wysłanie
wiadomości z żądaniem wypisania z Newslettera będzie skutkować niezwłocznym
rozwiązaniem umowy w zakresie świadczenia tej usługi.
                </span>
            </div>
            <div class="terms__content__info">
                <h3>
                    § 3 Reklamcje dot. newslettera
                </h3>
                <span>
                    1. Reklamacje dotyczące funkcjonowania Newslettera należy kierować na adres e-mail
sklep@kompre.pl.
                </span>
                                <span>
                    2. Rozpatrzenie reklamacji przez Sprzedawcę nastąpi w terminie do 14 dni.
                </span>
            </div>


                        <div class="terms__content__info">
                <h3>
                    Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń.
                </h3>
                <span>
W przypadku gdy postępowanie reklamacyjne nie przyniesie rezultatu oczekiwanego
przez Usługobiorcę będącego Konsumentem, Konsument może skorzystać m.in. z:
                </span>
                                <span>
                    a) mediacji prowadzonej przez właściwy terenowo Wojewódzki Inspektorat Inspekcji
Handlowej, do którego należy się zwrócić z wnioskiem o mediację. Wykaz
Inspektoratów znajduje się tutaj:
https://www.uokik.gov.pl/wazne_adresy.php#faq595;
                </span>
                                <span>
                                    b) pomocy właściwego terenowo stałego polubownego sądu konsumenckiego
działającego przy Wojewódzkim Inspektoracie Inspekcji Handlowej, do którego
należy złożyć wniosek o rozpatrzenie sprawy przed sądem polubownym. Wykaz
sądów dostępny jest pod adresem:
https://www.uokik.gov.pl/wazne_adresy.php#faq596;
                    
                </span>
                                <span>
                    c) bezpłatnej pomocy miejskiego lub powiatowego rzecznika Konsumentów;
                </span>
                                <span>
                    d) internetowej platformy ODR dostępnej pod adresem:
https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks.
                </span>
            </div>

            <div class="terms__content__info">
                <h3>
                    § 4 Dane osobowe
                </h3>
                <span>
1. Administratorem danych osobowych przekazanych przez Usługobiorcę w związku z
zapisem na Newsletter jest Usługodawca. Szczegółowe informacje dotyczące
przetwarzania danych osobowych przez Usługodawcę – w tym o pozostałych celach
oraz podstawach przetwarzania danych, a także o odbiorcach danych, znajdują się w
dostępnej w Sklepie Polityce prywatności – ze względu na zasadę przejrzystości,
zawartą w ogólnym rozporządzeniu Parlamentu Europejskiego i Rady (UE) o ochronie
danych – „RODO”.
                </span>
                                <span>
2. Celem przetwarzania danych Usługobiorcy jest wysyłka Newslettera. Podstawą
przetwarzania danych osobowych w tym przypadku jest umowa o świadczenie usługi
lub działania podejmowane na żądanie Usługobiorcy, zmierzające do jej zawarcia (art.
6 ust. 1 lit. b RODO), a także prawnie uzasadniony interes Usługodawcy, polegający
na przetwarzaniu danych w celu ustalenia, dochodzenia lub obrony ewentualnych
roszczeń (art. 6 ust. 1 lit. f RODO).
                </span>
                                <span>
3. Podanie danych przez Usługobiorcę jest dobrowolne, ale jednocześnie konieczne do
świadczenia usługi Newslettera. Niepodanie danych oznacza, że Usługodawca nie
będzie mógł świadczyć tej usługi.
                </span>
                                <span>
                    4. Dane Usługobiorcy będą przetwarzane do momentu, w którym:
                </span>
                                <span>
                    a) Usługobiorca wypisze się z Newslettera;
                </span>
                <span>
                    b) ustanie możliwość dochodzenia roszczeń przez Usługobiorcę lub
Usługodawcę, związanych z Newsletterem;
                </span>
                <span>
                    c) zostanie przyjęty sprzeciw Usługobiorcy wobec przetwarzania jego danych
osobowych – w przypadku gdy podstawą przetwarzania danych był
uzasadniony interes Usługodawcy – w zależności od tego, co ma zastosowanie w danym przypadku i co nastąpi
najpóźniej.
                </span>
                <span> Usługobiorcy przysługuje prawo żądania:</span>
                <span>a) dostępu do swoich danych osobowych,</span>
                <span> b) ich sprostowania,</span>
                <span> c) usunięcia,</span>
                <span> d) ograniczenia przetwarzania,</span>
                <span> e) przeniesienia danych do innego administratora
                a także prawo:</span>
                <span> wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania danych z
                przyczyn związanych ze szczególną sytuacją Usługobiorcy – wobec
                przetwarzania dotyczących go danych osobowych, opartego na art. 6 ust. 1 lit.
                f RODO (tj. na prawnie uzasadnionych interesach realizowanych przez
                administratora).</span>
                <span> 6. W celu realizacji swoich praw, Usługobiorca powinien skontaktować się z
                Usługodawcą.</span>
                <span> 7. W przypadku gdy Usługobiorca uzna, że jego dane są przetwarzane niezgodnie z
prawem, Usługobiorca może złożyć skargę do Prezesa Urzędu Ochrony Danych
Osobowych.</span>
            </div>

            <div class="terms__content__info">
                <h3>
                    § 5 Postanowienia końcowe
                </h3>
                <span>
                    1. Usługodawca zastrzega sobie prawo do zmiany niniejszego regulaminu tylko z
ważnych przyczyn. Jako ważną przyczynę rozumie się konieczność zmiany
regulaminu spowodowaną modernizacją usługi Newsletter lub zmianą przepisów
prawa, mającą wpływ na świadczenie usługi przez Usługodawcę.
                </span>
                <span>
                    2. Informacja o planowanej zmianie regulaminu zostanie wysłana na adres e-mail
Usługobiorcy podany w momencie zapisywania się do Newslettera co najmniej na 7
dni przed wprowadzeniem zmian w życie.
                </span>
                <span>
                    3. W przypadku gdy Usługobiorca nie sprzeciwi się planowanym zmianom do chwili
wejścia ich w życie, przyjmuje się, że akceptuje
                </span>
                <span>
                    4. W przypadku braku akceptacji dla planowanych zmian, Usługobiorca powinien
wysłać informację o tym na adres e-mail Usługodawcy: sklep@kompre.pl, co będzie
skutkować rozwiązaniem umowy o świadczenie usługi z chwilą wejścia w życie
planowanych zmian.
                </span>
                <span>
                    5. Zakazane jest dostarczanie przez Usługobiorcę treści o charakterze bezprawnym.
                </span>
                <span>
                    6. Umowa o świadczenie usługi Newslettera zawierana jest w języku polskim.
                </span>
                <span>
                    7. W przypadku Usługobiorcy niebędącego Usługobiorcą uprzywilejowanym, sądem
właściwym będzie sąd właściwy dla siedziby Usługodawcy.
                </span>
            </div>
            



            
        </div>
        <trust/>
        <ftr/>
    </div>
</template>
<script>
// Imports
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";

export default {
    data() {
      return {
      }
    },
    mounted()
    {
        window.scrollTo(0,0);
    },
    components: {navbar,trust,ftr},
}
</script>
<style lang="scss" scoped>
    @import '../styles/variables';



    h1 {
        text-align: left;
        user-select: none;
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 500;
        font-size: 1.563rem;
    }

    h3
    {
        text-align: left;
        font-weight: 500;
        padding: 0;
        font-size: 1.25rem;
        margin: 0;
    }

    h4
    {
        text-align: left;
        font-weight: 400;
        padding: 0;
        font-size: 1.25rem;
        margin: 0;
    }

    .terms
    {
        display: flex;
        flex-direction: column;
        width: 100vw;

        &__content
        {
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            margin-top: 10.527em;

            &__info
            {
                margin-top: 1.25rem;
                margin-bottom: 1.25rem;
                display: flex;
                flex-direction: column;

                span
                {
                    margin-top: 0.64rem;
                }
            }
        }
    }

    .question
    {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        font-weight: 500;
    }

    .answer
    {
        font-weight: 400;
    }
@media (max-width: 769px) {
    .terms__content{
        margin-top: 4.768em;
    }
}

</style>